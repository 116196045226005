import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect, useRef } from 'react';

import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import { getDefaultRegistry } from "@rjsf/core";
import Form, { generateWidgets, Widgets } from "@rjsf/mui";

import TextareaAutosize from '@mui/material/TextareaAutosize';
import { makeStyles } from '@mui/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const widgets = generateWidgets();
widgets.TextWidget = widgets.TextareaWidget;
//const TextareaWidget = Widgets.TextareaWidget;


const theme = createTheme({
  spacing: (factor) => `${0.1 * factor}rem`,
  typography: {
    fontFamily: ['"IBM Plex Sans"','serif'].join(','),
    fontSize: 12,
  },
});
/*
const useStyles = makeStyles({
  textAreaWithStyle: {
    fontFamily: ['"IBM Plex Mono"','serif'].join(','),
    fontSize: '0.5em',
    //textarea: {color: 'green',},
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
    fontSize: '20px',
    color: 'blue',
    resize: 'none',
  },
  textAreaWithoutStyle: {
    resize: 'none',
  },
});
*/
function App() {
  /*const classes = useStyles();
  const returnStyleBasedOnInput = () => {
    return classes.textAreaWithStyle
  };
  */
  const queryParams = new URLSearchParams(window.location.search)


  const defaultSchema: RJSFSchema = {
    title: 'Todo',
    type: 'object',
    required: ['title'],
    properties: {
      title: { type: 'string', title: 'Title', default: 'A new task' },
      done: { type: 'boolean', title: 'Done?', default: false },
    },
  };
  const newSchema: RJSFSchema = {
  	title:"pipelineId#slug",
    "type": "object",
    "properties": {
      "project": {
        "type": "string",
        "title": "PROJECT",
        "description": "Your Project",
        "minLength": 1
      },
      "outline": {
        "type": "array",
  	  title: "Outline",
        "items": {
          "title": "itemOf_outline",
          "type": "object",
          "properties": {
            "head": {
              "type": "string",
              "minLength": 3
            },
            "description": {
              "type": "string",
              "minLength": 3
            },
            "words": {
              "type": "number"
            },
            "searches": {
              "type": "array",
  			title: "searcheur",
              "items": {
                "title": "itemOf_searches",
                "type": "object",
                "properties": {
                  "text": {
                    "type": "string",
                    "minLength": 1
                  },
                  "count": {
                    "type": "number",
                    "minimum": 1,
                    "maximum": 20
                  },
                  "offset": {
                    "type": "number"
                  },
                  "filter": {
                    "type": "string"
                  }
                }
              }
            }
          },
          "required": [
            "head",
            "description"
          ]
        }
      },
      "newSources": {
  		   title:'New Sources',
        "type": "boolean"
      },
      "citationStyle": {
  		title:'Citation Style',
        "type": "string",
        "enum": [
          "vancouver",
          "apa",
          "chicago",
          "mla",
          "harvard"
        ]
      },
      "translate": {
  		title:'Translate (optional)',
        "type": "string",
        "minLength": 1,
        description: 'Translate your output to a language of your choice',
      },
      "pdf": {
  		title:'Generate Pdf',
        "type": "boolean"
      }
    },
    "required": [
      "project"
    ]
  };


  const [formData, setFormData] = useState({});
  const [session, setSession] = useState('queued');
  const [errorMessage, setErrorMessage] = useState('');
  const [schema, setSchema] = useState({});
  const [userKey, setUserKey] = useState('');
  const [pipelineId, setPipelineId] = useState('');
  const [pipelineData, setPipelineData] = useState({});
  const [processing, setProcessing] = useState(false);

  const [rawQuery, setRawQuery] = useState('{}');
  const [rawQueryValid, setRawQueryValid] = useState(true);
  const [parsedRawQuery, setParsedRawQuery] = useState('');


  async function loadSession(){
    let _session = queryParams.get("session")
    if (_session) {
      const req = await fetch(`https://api.raiden.ai/web/run/session/load/${_session}`)
      const response = await req.json()
      // console.log('response',response)
      if (!response.status){
        setErrorMessage(response.error)
        setSession(false)
      }
      setUserKey(response.key)
      setPipelineId(response.pipelineId)
      setPipelineData({
        name: response.pipeline.name,
        description: response.pipeline.description,
      })
      let _schema = false
      if (response.pipeline.schema) {
        if (typeof(response.pipeline.schema) === 'object'){
          if ( JSON.stringify(response.pipeline.schema) != '{}' ) {
            _schema = response.pipeline.schema
          }
        }
      }
      setSchema(_schema)
      if (response.pipeline.example) setFormData(response.pipeline.example)
      setSession(true)
    }
    else {
      setErrorMessage(`no session provided`)
      setSession(false)
    }
    /*
    setUserKey( queryParams.get("userKey") )
    setPipelineId ( queryParams.get("pipelineId") )
    setSchema(newSchema)
    setFormData({project:"pat"})
    setSession(true)
    */

    // if session proper, load the stuff and setSession(true)
    // else load error, setSession(false)
    // if status false setErrorMessage on the returned error!
    // setFormData if example exists in response

    return;
  }
  React.useEffect(() => {
    // on mount
    loadSession()
    return () => {
        console.log('Component onUnmount');
    };
  }, []);

  function tryParseQuery(_raw){
    //console.log(_raw)
    setParsedRawQuery(_raw)
    try {
      let _parsed = JSON.parse(_raw)
      setParsedRawQuery(JSON.stringify(_parsed,null,`  `))
      setRawQueryValid(true)
    }catch(e){
      setParsedRawQuery('')
      setRawQueryValid(false)
      return false
    }
    /*
    try {
      let _parsed = JSON.parse(_raw)
      setParsedRawQuery(_parsed)
    }catch(e){false}
    */
  }

  function handleRawQueryChange(event) {
    // 👇️ access textarea value
    setRawQuery(event.target.value);
    //console.log(event.target.value);
    tryParseQuery(event.target.value)
  }

  const rawqueryRef = useRef();
  function handleKeyDown(e) {
   if (e.key === "Tab") {
     e.preventDefault();
     const { selectionStart, selectionEnd } = e.target;
     const newText =
       rawQuery.substring(0, selectionStart) +
       '  ' + // Edit this for type tab you want
              // Here it's 2 spaces per tab
       rawQuery.substring(selectionEnd, rawQuery.length);

     rawqueryRef.current.focus();
     rawqueryRef.current.value = newText;

     rawqueryRef.current.setSelectionRange(
       selectionStart + 2,
       selectionStart + 2
     );

     setRawQuery(newText);
   }
 }

  const log = (type) => console.log.bind(console, type);

  async function runPipeline(query){
    if (!processing){
      setProcessing(true)
      let request = {pipelineId,query}
      console.log('runPipeline' , request)
      fetch('https://api.raiden.ai/actions/pipeline/run', {
        method: 'POST',
        headers:{
          'Content-Type': 'application/json',
          key:	userKey,
        },
        body: JSON.stringify(request)
      })
      await new Promise(r => setTimeout(r, 1000));
      window.location.href = 'https://private.raiden.ai/pipeline/executions?filter=' + encodeURIComponent(pipelineId)
    }
    // redirect to private.raiden.ai / pipeline id filter etc
  }
  async function runPipeline_rawQuery(){
    let query = JSON.parse(parsedRawQuery)
    runPipeline(query)
  }

  if (session === 'queued') return(
    <div className="App">
    <div class="max-w-7xl mx-auto my-2 p-0">
      <div class="">

      <h1 class="text-sm p-3 pt-1 text-gray-500">
      <a href="https://private.raiden.ai/pipelines" class="text-base text-blue-500 hover:text-blue-700 hover:underline cursor-pointer pr-10">
        ← Back
      </a>
      RAIDEN.AI | Pipeline Execution Autoform Widget
      </h1>
    </div>
    <div class=""><h1 class="text-sm p-3 pt-1 text-black my-4">loading session</h1></div>
    </div>
    <div class="mx-auto px-8 mt-2 h-[30vh]">
      <div role="status" class="w-20 mt-[4vh] mx-auto">
      <svg aria-hidden="true" class="w-20 h-20 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
      <span class="sr-only">Loading...</span>
      </div>
    </div>
    </div>
  )
  else if (session === false) return(
    <div className="App">
    <div class="max-w-7xl mx-auto my-2 p-0">
      <div class="">

      <h1 class="text-sm p-3 pt-1 text-gray-500">
      <a href="https://private.raiden.ai/pipelines" class="text-base text-blue-500 hover:text-blue-700 hover:underline cursor-pointer pr-10">
        ← Back
      </a>
      RAIDEN.AI | Pipeline Execution Autoform Widget
      </h1>
    </div>
    <div class="max-w-6xl mx-auto bg-red-50 p-4 px-8 my-8 text-sm my-4">invalid session: {errorMessage}</div>
    </div>
    </div>
  )
  else if (processing) return(
    <div className="App"><div class="mx-auto px-8 mt-10 h-[90vh]">
      <div role="status" class="w-20 mt-[30vh] mx-auto">
      <svg aria-hidden="true" class="w-20 h-20 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
      <span class="sr-only">Loading...</span>
      </div>
    </div></div>
  )
  else if (schema === false) return(
    <div className="App">

    <div class="max-w-7xl mx-auto my-2 p-0 mb-32">
      <div class="">
        <a href="https://private.raiden.ai/pipelines" class="text-base text-blue-500 hover:text-blue-700 hover:underline cursor-pointer pr-10">
          ← Back
        </a>
        <h1 class="text-sm p-3 pt-1 text-gray-500">RAIDEN.AI | Pipeline Execution Autoform Widget</h1>
      </div>
      <div class="grid grid-cols-12 m-2 bg-gray-50 p-4 py-2">
        <div class="my-1 col-span-12"><p class="text-sm uppercase p-3 pt-1 text-gray-400">PIPELINE DETAILS</p></div>
        <div class="my-1 col-span-1"><p class="text-xs p-3">pipelineId</p></div>
        <div class="my-1 col-span-5 bg-white"><p class="text-xs p-3">{pipelineId}</p></div>
        <div class="my-1 col-span-1"><p class="text-xs p-3">name</p></div>
        <div class="my-1 col-span-5 bg-white"><p class="text-xs p-3">{pipelineData.name}</p></div>
        <div class="my-1 col-span-1"><p class="text-xs p-3">description</p></div>
        <div class="my-1 col-span-11 bg-white">
          <p class="text-xs p-3">
            {pipelineData.description}
          </p>
        </div>
      </div>


      <div class="grid grid-cols-12">
        <div class="col-span-12 p-4 py-0">
          <p class="text-xs p-1 px-3"><span class="font-bold">{pipelineId}</span> has no defined query schema. Put your JSON query object below.</p>
        </div>
        <div class="col-span-7 p-4">
          <h1 class="text-sm p-3 pt-1 text-gray-500 uppercase">Raw Query</h1>
          <textarea ref={rawqueryRef} onKeyDown={handleKeyDown}
            class="w-full h-[20vw] max-h-[50vh] resize-y overflow-y-auto text-sm text-gray-900 bg-gray-200 m-2 p-4 "
            value={rawQuery} onChange={handleRawQueryChange} >
          </textarea>
        </div>
        <div class="col-span-5 p-4">
          <h1 class="text-sm p-3 pt-1 text-gray-500 uppercase">
            Result Query JSON : <span class="text-green-500">{rawQueryValid ? 'Valid' : ''}</span><span class="text-red-500">{rawQueryValid ? '' : 'Invalid'}</span>
          </h1>
          <p class="w-full h-[20vw] max-h-[50vh] resize-y overflow-y-auto text-xs text-black bg-gray-100 m-2 p-4 whitespace-pre-wrap break-all">{parsedRawQuery}
          </p>
          <div class="p-4 px-1">
          <button onClick={(e)=> { runPipeline_rawQuery()  }}
                  class="text-white bg-blue-700 w-full
                  hover:bg-blue-800 focus:outline-none font-medium rounded
                  text-sm px-5 py-7 text-center">
                  Run Pipeline →
          </button>
          </div>
        </div>
      </div>
    </div>


    </div>
  )
  else return (
    <ThemeProvider theme={theme}>
    <div className="App">
    <div class="max-w-7xl mx-auto my-2 p-0">
      <div class="">

      <h1 class="text-sm p-3 pt-1 text-gray-500">
      <a href="https://private.raiden.ai/pipelines" class="text-base text-blue-500 hover:text-blue-700 hover:underline cursor-pointer pr-10">
        ← Back
      </a>
      RAIDEN.AI | Pipeline Execution Autoform Widget
      </h1>
      </div>
      <div class="grid grid-cols-12 m-2 bg-gray-50 p-4 py-2">
        <div class="my-1 col-span-12"><p class="text-sm uppercase p-3 pt-1 text-gray-400">PIPELINE DETAILS</p></div>
        <div class="my-1 col-span-1"><p class="text-xs p-3">pipelineId</p></div>
        <div class="my-1 col-span-5 bg-white"><p class="text-xs p-3">{pipelineId}</p></div>
        <div class="my-1 col-span-1"><p class="text-xs p-3">name</p></div>
        <div class="my-1 col-span-5 bg-white"><p class="text-xs p-3">{pipelineData.name}</p></div>
        <div class="my-1 col-span-1"><p class="text-xs p-3">description</p></div>
        <div class="my-1 col-span-11 bg-white">
          <p class="text-xs p-3">
            {pipelineData.description}
          </p>
        </div>
      </div>
    </div>
    <div class="max-w-7xl mx-auto bg-gray-50 p-4 px-6 mt-4 mb-24">
    <Form
        // className={returnStyleBasedOnInput()}
        schema={schema}
        validator={validator}
        formData={formData}
        widgets={widgets}
        // onChange={()=>{true}}
        onSubmit={(e)=> {runPipeline(e.formData)}}
        //onError={log('errors')}
        liveValidate
      />
    </div>
    </div>
    </ThemeProvider>
  );
}
export default App;
